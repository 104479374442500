import {
  formatPostalAddress,
  formatStreetname,
} from "src/components/Homes/HomeDetails/Overview/addressUtils"
import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { CopyWrapper } from "src/components/Paradise/sharedStyles"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { Routes } from "src/router/routes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { formatDate } from "src/utils/l10n"

export function ParadiseHomeGroupOverview({
  homegroup,
}: {
  homegroup: THomeGroup
}) {
  return (
    <ParadiseOverviewExpandable rawJson={homegroup}>
      <ParadiseSettingStatic
        title="Home group id"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{homegroup.id}</MText>
            <CopyText
              value={homegroup.id}
              copiedText="Copied home id"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic title="Name" value={homegroup.name} />

      <ParadiseSettingStatic
        title="Organization id"
        value={
          <CopyWrapper>
            <InternalLink
              to={Routes.ParadiseOrganization.location(
                homegroup.organization_id
              )}
            >
              {homegroup.organization_id}
            </InternalLink>
            <CopyText
              value={homegroup.organization_id}
              copiedText="Copied organization id"
              width={18}
            />
          </CopyWrapper>
        }
      />

      <ParadiseSettingStatic
        title="Address"
        value={
          homegroup.address ? (
            <div>
              <div>{formatStreetname(homegroup.address)}</div>
              <div>{formatPostalAddress(homegroup.address)}</div>
            </div>
          ) : (
            "-"
          )
        }
      />

      <ParadiseSettingStatic
        title="Location"
        value={
          homegroup.location ? (
            <ExternalLink
              href={`https://maps.google.com/maps?q=${homegroup.location?.latitude},${homegroup.location?.longitude}`}
            >
              View on Google maps
            </ExternalLink>
          ) : (
            "-"
          )
        }
      />

      <ParadiseSettingStatic
        title="Created at"
        value={formatDate({
          date: homegroup.created_at,
          timezone: "utc",
          clockType: "24",
        })}
      />
      <ParadiseSettingStatic
        title="Updated at"
        value={
          homegroup.updated_at
            ? formatDate({
                date: homegroup.updated_at,
                timezone: "utc",
                clockType: "24",
              })
            : "-"
        }
      />
    </ParadiseOverviewExpandable>
  )
}
