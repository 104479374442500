import { Pager } from "src/components/Pager/Pager"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchHomeGroupHomes } from "src/data/homeGroups/queries/homeGroupHomeQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { InternalLink } from "src/ui/Link/InternalLink"

const LIMIT = 15

export function ParadiseHomeGroupHomes({
  orgId,
  homeGroupId,
}: {
  orgId: string
  homeGroupId: string
}) {
  const { navigate } = useRouter()

  const { searchParams, setSearchParams } = useSearchParams({
    keys: [
      {
        key: "homes_offset",
        type: "number",
      },
    ],
  })

  const fetchHomeGroupHomes = useFetchHomeGroupHomes({
    organizationId: orgId,
    homeGroupId: homeGroupId,
    filter: {
      limit: LIMIT,
      offset: searchParams.homes_offset ?? 0,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const headers = [
    <div key={"home-id"}>Home id</div>,
    <div key={"name"}>Name</div>,
  ]

  const rows =
    fetchHomeGroupHomes.data?.homes.map((home) => (
      <>
        <InternalLink
          to={Routes.ParadiseHome.location(home.id)}
          onClick={(e) => e.stopPropagation()}
        >
          {home.id}
        </InternalLink>
        <div>{home.name}</div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Homes" />
      <ParadiseTable
        header={headers}
        rows={rows}
        isLoading={fetchHomeGroupHomes.isPreviousData}
        onRowClick={(index) => {
          const homeGroupId = fetchHomeGroupHomes.data?.homes[index]?.id

          if (!homeGroupId) {
            throw new Error("Home group id is not found when clicking on row")
          }

          navigate(Routes.ParadiseHome.location(homeGroupId))
        }}
        templateColumns="250px 1fr"
        error={{
          hasError: fetchHomeGroupHomes.isError,
          title: fetchHomeGroupHomes.error?.message,
        }}
      />
      <Pager
        limit={LIMIT}
        offset={searchParams.homes_offset ?? 0}
        setOffset={(offset) => {
          setSearchParams("homes_offset", offset)
        }}
        totalCount={fetchHomeGroupHomes.data?.paging.total_count}
      />
    </div>
  )
}
