import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseHomeGroupHomes } from "src/components/Paradise/ParadiseHomeGroups/ParadiseHomeGroupDetails/ParadiseHomeGroupHomes/ParadiseHomeGroupHomes"
import { ParadiseHomeGroupOverview } from "src/components/Paradise/ParadiseHomeGroups/ParadiseHomeGroupDetails/ParadiseHomeGroupOverview/ParadiseHomeGroupOverview"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchHomeGroup } from "src/data/homeGroups/queries/homeGroupQueries"
import { useFetchOrganization } from "src/data/organizations/queries/organizationQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseHomeGroupDetails({
  orgId,
  homeGroupId,
}: {
  orgId: string
  homeGroupId: string
}) {
  const { t, langKeys } = useTranslate()

  const fetchOrganization = useFetchOrganization({
    id: orgId,
  })

  const fetchHomeGroup = useFetchHomeGroup({
    orgId: orgId,
    homeGroupId: homeGroupId,
  })

  useAddRecentlyViewedPage({
    title: fetchHomeGroup.data?.name,
    enitytId: fetchHomeGroup.data?.id,
    type: "Home group",
    enabled: fetchHomeGroup.isSuccess,
  })

  if (fetchOrganization.isError || fetchHomeGroup.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchOrganization.isLoading || fetchHomeGroup.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const organization = fetchOrganization.data
  const homeGroup = fetchHomeGroup.data

  return (
    <ParadiseDetailsLayout
      meta={<ParadiseDetailsHeading>{homeGroup.name}</ParadiseDetailsHeading>}
      left={<ParadiseHomeGroupOverview homegroup={homeGroup} />}
      right={<ParadiseHomeGroupHomes orgId={orgId} homeGroupId={homeGroupId} />}
      breadcrumbs={[
        {
          label: organization.name,
          to: Routes.ParadiseOrganization.location(orgId),
        },
        {
          label: `(Home group) ${homeGroup.id}`,
          to: Routes.ParadiseHomeGroup.location({ orgId, homeGroupId }),
        },
      ]}
      breadcrumbCopyValue={homeGroup.id}
    />
  )
}
